export const GET_BOT_DETAILS = 'app/BotDetails/GET_BOT_DETAILS'
export const SET_BOT_DETAILS = 'app/BotDetails/SET_BOT_DETAILS'

export const GET_BOT_ASSIGNMENTS = 'app/BotDetails/GET_BOT_ASSIGNMENTS'
export const SET_BOT_ASSIGNMENTS = 'app/BotDetails/SET_BOT_ASSIGNMENTS'

export const GET_BOT_ACTIONS = 'app/BotDetails/GET_BOT_ACTIONS'
export const SET_BOT_ACTIONS = 'app/BotDetails/SET_BOT_ACTIONS'

export const VALIDATE_SOURCE_URL = 'app/BotDetails/VALIDATE_SOURCE_URL'
export const VALIDATE_SOURCE_URL_SUCCESS =
  'app/BotDetails/VALIDATE_SOURCE_URL_SUCCESS'
export const CLEAR_SOURCE_URL_VALIDATION =
  'app/BotDetails/CLEAR_SOURCE_URL_VALIDATION'

export const UPDATE_BOT = 'app/BotDetails/UPDATE_BOT'
export const UPDATE_BOT_SUCCESS = 'app/BotDetails/UPDATE_BOT_SUCCESS'

export const SET_BOT_ERROR = 'app/BotDetails/SET_BOT_ERROR'

export const HANDLE_REFRESH_BOT = 'app/BotDetails/HANDLE_REFRESH_BOT'
export const HANDLE_REFRESH_BOT_SUCCESS =
  'app/BotDetails/HANDLE_REFRESH_BOT_SUCCESS'
