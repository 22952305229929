import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import qs from 'query-string'
import { push } from 'connected-react-router'
import DataTable from 'components/DataTable'
import CenterContent from 'components/CenterContent'
import SectionHeader from 'components/SectionHeader'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

import { selectBot, selectBotAssignments } from './selectors'
import { getBotAssignments } from './actions'

class BotAssignmentsTab extends Component {
  constructor(props) {
    super(props)

    props.getBotAssignments(props.botData.getIn(['data', 'srn']))
  }

  getSwimlane = srn => {
    const swimlane = this.props.swimlanes.find(
      swimlane => swimlane.get('srn') === srn
    )
    return swimlane ? swimlane.toJS() : {}
  }

  getAssignments = () => {
    if (
      this.props.botAssignments &&
      !this.props.botAssignments.get('data').isEmpty()
    ) {
      return this.props.botAssignments
        .get('data')
        .toJS()
        .filter(item => item !== null && item.content)
        .map(item => {
          const swimlane =
            item.content && item.content.srn
              ? this.getSwimlane(item.content.srn)
              : {}
          return {
            swimlaneName: swimlane.title || swimlane.name || '-',
            swimlaneDescription: swimlane.description || '-',
            lastModified: swimlane.lastModified || '-',
            botAssignmentSrn: item.srn,
            srn: swimlane.srn || '-',
          }
        })
    }
    return []
  }

  handleSwimlaneNav = srn => {
    if (srn) {
      this.props.push({
        pathname: '/App/SwimlaneDetails',
        search: qs.stringify({
          srn: srn,
        }),
      })
    }
  }

  render() {
    if (this.props.botAssignments.get('isLoading')) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <SquareLoadingAnimation />
        </CenterContent>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <SectionHeader style={{ paddingBottom: '1rem' }}>
          Swimlanes
        </SectionHeader>
        <div style={{ height: '100%' }}>
          <DataTable
            noTableActions
            data={this.getAssignments()}
            hiddenColumns={['srn', 'botAssignmentSrn']}
            onClickNodeView={this.handleSwimlaneNav}
          />
        </div>
      </div>
    )
  }
}

BotAssignmentsTab.propTypes = {
  botData: ImmutablePropTypes.map,
  getBotAssignments: PropTypes.func,
  botAssignments: ImmutablePropTypes.map,
  swimlanes: ImmutablePropTypes.iterable,
  push: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  botData: selectBot,
  botAssignments: selectBotAssignments,
  swimlanes: selectSwimlanes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      getBotAssignments,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(BotAssignmentsTab)
