import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import _ from 'lodash'

import CloudBadge from 'components/CloudBadge'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import SectionHeader from 'components/SectionHeader'
import JSONDisplay from 'components/JSONDisplay'
import CenterContent from 'components/CenterContent'

import { selectBot } from './selectors'
class ManifestDetails extends Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: '0.5fr 1fr',
      gridGap: '1rem',
    },
    label: {
      fontSize: '1rem',
      fontWeight: '400',
      color: this.props.theme.neutralMedium,
      paddingBottom: '0.25rem',
    },
    title: {
      fontSize: '22px',
      fontWeight: '300',
    },
    property: {
      padding: '0.5rem 1rem',
    },
  }

  render() {
    const title = this.props.botData.getIn(['data', 'title'])
    const description = this.props.botData.getIn(['data', 'description'])
    const operation = this.props.botData.getIn(['data', 'operation'])
    const cloud = this.props.botData.getIn(['data', 'cloud'])
    const type = this.props.botData.getIn(['data', 'type'])
    const json = this.props.botData.getIn(['data', 'blob'])
      ? this.props.botData.getIn(['data', 'blob']).toJS()
      : {}
    const remediationType = this.props.botData.getIn([
      'data',
      'remediationType',
    ])
    if (this.props.botData.get('isLoading')) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <SquareLoadingAnimation />
        </CenterContent>
      )
    }

    return (
      <div style={this.styles.container}>
        <div style={this.styles.detailsColumn}>
          <SectionHeader> Details </SectionHeader>
          <div style={this.styles.property}>
            <div style={this.styles.label}>Title </div>
            <div style={{ overflow: 'auto' }}>{title}</div>
          </div>
          <div style={this.styles.property}>
            <div style={this.styles.label}>Description </div>
            <div style={{ overflow: 'auto' }}>{description || '-'}</div>
          </div>
          <div style={this.styles.property}>
            <div style={this.styles.label}>Bot Type </div>
            <div> {type ? _.startCase(_.toLower(type)) : '-'}</div>
          </div>
          {type === 'Remediation' && (
            <div style={this.styles.property}>
              <div style={this.styles.label}>Remediation Type </div>
              <div style={{ overflow: 'auto' }}>
                {remediationType
                  ? _.startCase(_.toLower(remediationType))
                  : '-'}
              </div>
            </div>
          )}

          <div style={this.styles.property}>
            <div style={this.styles.label}>Operation </div>
            <div>{operation ? _.startCase(_.toLower(operation)) : '-'}</div>
          </div>
          <div style={this.styles.property}>
            <div style={this.styles.label}>Cloud </div>
            <CloudBadge
              style={{ fontSize: '1.5rem' }}
              type={_.toLower(cloud || '')}
            />
            &nbsp;{cloud}
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <SectionHeader> Bot Code </SectionHeader>
          <div>
            <JSONDisplay content={json} />
          </div>
        </div>
      </div>
    )
  }
}

ManifestDetails.propTypes = {
  botData: ImmutablePropTypes.map,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  botData: selectBot,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(themeable(ManifestDetails))
