import {
  GET_BOT_DETAILS,
  SET_BOT_DETAILS,
  GET_BOT_ASSIGNMENTS,
  SET_BOT_ASSIGNMENTS,
  GET_BOT_ACTIONS,
  SET_BOT_ACTIONS,
  VALIDATE_SOURCE_URL,
  VALIDATE_SOURCE_URL_SUCCESS,
  CLEAR_SOURCE_URL_VALIDATION,
  UPDATE_BOT,
  UPDATE_BOT_SUCCESS,
  SET_BOT_ERROR,
  HANDLE_REFRESH_BOT,
  HANDLE_REFRESH_BOT_SUCCESS,
} from './constants'
import { createAction } from 'redux-actions'

export const getBotDetails = createAction(GET_BOT_DETAILS)
export const setBotDetails = createAction(SET_BOT_DETAILS)
export const getBotAssignments = createAction(GET_BOT_ASSIGNMENTS)
export const setBotAssignments = createAction(SET_BOT_ASSIGNMENTS)
export const getBotActions = createAction(GET_BOT_ACTIONS)
export const setBotActions = createAction(SET_BOT_ACTIONS)

export const validateSourceUrl = createAction(VALIDATE_SOURCE_URL)
export const validateSourceUrlSuccess = createAction(
  VALIDATE_SOURCE_URL_SUCCESS
)

export const clearSourceUrlValidation = createAction(
  CLEAR_SOURCE_URL_VALIDATION
)

export const updateBot = createAction(UPDATE_BOT)
export const updateBotSuccess = createAction(UPDATE_BOT_SUCCESS)

export const setBotError = createAction(SET_BOT_ERROR)

export const handleRefreshBot = createAction(HANDLE_REFRESH_BOT)
export const handleRefreshBotSuccess = createAction(HANDLE_REFRESH_BOT_SUCCESS)
