import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import moment from 'moment'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import { Input, Label } from 'reactstrap'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import Button from 'components/Button'
import BorderlessButton from 'components/BorderlessButton'
import TextLink from 'components/TextLink'

class BotProperties extends Component {
  constructor(props) {
    super(props)

    this.styles = {
      container: {
        display: 'flex',
      },
      label: {
        fontSize: '1rem',
        fontWeight: '400',
        color: this.props.theme.neutralMedium,
        paddingBottom: '0.25rem',
      },
      property: {
        padding: '0.5rem 1rem',
      },
      input: {
        margin: '0.25rem 0rem 0.75rem 0rem',
      },
      error: {
        fontSize: '0.8rem',
        fontWeight: '400',
        margin: '0.25rem 0rem 0.5rem 0.25rem',
        color: this.props.theme.red,
      },
      success: {
        fontSize: '0.8rem',
        fontWeight: '400',
        margin: '0.25rem 0rem 0.5rem 0.25rem',
      },
      refresh: {
        marginLeft: '0.5rem',
        fontSize: '0.75rem',
        color: this.props.theme.neutralMedium,
        cursor: 'pointer',
      },
    }

    this.state = {
      editSource: false,
      sourceUrl: props.botData.sourceUrl || '',
    }
  }

  getStatusColor = status => {
    if (status == 'FAILED_TO_SYNC') {
      return this.props.theme.red
    } else {
      return this.props.theme.success
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isValid !== undefined) {
      if (prevState.sourceUrl !== this.state.sourceUrl) {
        this.props.clearSourceUrlValidation()
      }
      if (prevProps.isValid !== this.props.isValid && this.props.isValid) {
        this.handleClose()
      }
    }
    if (prevState.editSource === true && this.state.editSource === false) {
      this.handleClose()
    }
  }

  handleClose = () => {
    this.setState({
      sourceUrl: this.props.botData.sourceUrl,
      editSource: false,
    })
    this.props.clearSourceUrlValidation()
  }

  handleUpdateSourceUrl = () => {
    this.props.validateSourceUrl({
      url: this.state.sourceUrl,
      srn: this.props.botData.srn,
    })
  }

  renderSourceUrlInput = () => {
    const { isValid } = this.props
    const isNotValid = isValid !== undefined && isValid === false
    return (
      <div style={{ margin: '0rem 0rem 1rem 0rem' }}>
        <Label for="sourceType">Source Url</Label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Input
            type="text"
            onChange={e => this.setState({ sourceUrl: e.target.value })}
            value={this.state.sourceUrl}
          />
        </div>
        {isNotValid && <div style={this.styles.error}> URL is not valid </div>}
      </div>
    )
  }

  getPopoverBody = () => {
    const { sourceUrl } = this.state
    const { isValidating } = this.props
    const canUpdate =
      sourceUrl !== '' &&
      !isValidating &&
      sourceUrl !== this.props.botData.sourceUrl

    return (
      <div style={{ width: '400px', padding: '1rem' }}>
        {this.renderSourceUrlInput()}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <TextLink
            style={{
              color: this.props.theme.primary,
              marginRight: '0.5rem',
            }}
            onClick={() =>
              this.setState({
                editSource: false,
                sourceUrl: this.props.botData.sourceUrl,
              })
            }
          >
            Cancel
          </TextLink>
          <Button
            disabled={!canUpdate}
            color="primary"
            onClick={this.handleUpdateSourceUrl}
          >
            {isValidating ? <Icon fa name="sync" spin /> : 'Update'}
          </Button>
        </div>
      </div>
    )
  }

  handleRefresh = () => {
    const { srn } = this.props.botData
    this.props.handleRefreshBot({ srn })
  }

  render() {
    const isEditSourceDisabled = false // this.props.botData.source === "SONRAI"
    let { isCustom, url, status, lastSync } = this.props.botData
    return (
      <div style={this.styles.container}>
        <div style={this.styles.property}>
          <div style={this.styles.label}>Owner </div>
          <div> {isCustom ? 'Custom' : 'Sonrai'}</div>
        </div>
        <div style={this.styles.property}>
          <div style={this.styles.label}>Source </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              title={url}
              style={
                url && { color: this.props.theme.primary, cursor: 'pointer' }
              }
              onClick={() => url && window.open(url, '_blank')}
            >
              URL
            </div>
            <WithPermission
              permissionName="edit.bots"
              resourceId={this.props.botData.resourceId}
            >
              <Popover
                isOpen={isEditSourceDisabled ? false : this.state.editSource}
                position="right-start"
              >
                <PopoverAnchor>
                  <div
                    style={{ margin: '0rem 0rem 0rem 0.75rem' }}
                    onClick={() =>
                      this.setState(state => ({
                        editSource: !state.editSource,
                      }))
                    }
                  >
                    <Icon
                      fa
                      name={'pencil-alt'}
                      style={{
                        color: isEditSourceDisabled
                          ? this.props.theme.neutralMedium
                          : this.props.theme.primary,
                        fontSize: '0.95rem',
                      }}
                    />
                  </div>
                </PopoverAnchor>
                <PopoverBody> {this.getPopoverBody()}</PopoverBody>
              </Popover>
            </WithPermission>
          </div>
        </div>
        <div style={this.styles.property}>
          <div style={this.styles.label}>Status </div>
          <div style={{ color: this.getStatusColor(status) }}>
            {status || ''}
          </div>
        </div>
        <div style={this.styles.property}>
          <div style={this.styles.label}>Last Update</div>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            title={moment(lastSync).format('lll')}
          >
            <span>{moment(lastSync).fromNow()} </span>
            <WithPermission
              permissionName="edit.bots"
              resourceId={this.props.botData.resourceId}
            >
              <BorderlessButton
                style={this.styles.refresh}
                onClick={this.handleRefresh}
                title="Refetch bot source"
              >
                <Icon fa name="refresh" />
              </BorderlessButton>
            </WithPermission>
          </div>
        </div>
      </div>
    )
  }
}
BotProperties.propTypes = {
  theme: themeShape,
  botData: PropTypes.object,
  isValid: PropTypes.bool,
  isValidating: PropTypes.bool,
  validateSourceUrl: PropTypes.func,
  clearSourceUrlValidation: PropTypes.func,
  handleRefreshBot: PropTypes.func,
}

export default themeable(BotProperties)
