import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import qs from 'query-string'
import _ from 'lodash'

import RedirectWithoutPermission from 'containers/PermissionChecker/RedirectWithoutPermission'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Scrollable from 'components/Scrollable'
import { NodeViewTabs } from 'components/NodeView'
import LoadingAnim from 'components/LoadingAnim'
import BorderedCard from 'components/BorderedCard'
import Error from 'components/Error'

import reducer from './reducer'
import saga from './sagas'
import {
  getBotDetails,
  clearSourceUrlValidation,
  validateSourceUrl,
  handleRefreshBot,
} from './actions'
import {
  selectBot,
  selectSourceUrlValidation,
  selectBotError,
} from './selectors'
import BotProperties from './BotProperties'
import BotAssignmentsTab from './BotAssignmentsTab'
import ActivityTab from './ActivityTab'
import ManifestDetails from './ManifestDetails'
import { Helmet } from 'react-helmet'
import { getNameForHelmet } from 'utils/sonraiUtils'

class BotDetails extends Component {
  constructor(props) {
    super(props)

    const srn = qs.parse(_.get(props, ['location', 'search'], '')).botId || ''
    props.getBotDetails({ srn })

    this.styles = {
      container: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        padding: '1rem',
        overflow: 'auto',
      },
      tabContainer: {
        height: '100%',
        padding: '1rem',
        backgroundColor: this.props.theme.light,
        borderTop: 0,
        borderBottom: `1px solid ${this.props.theme.neutralMedium}`,
        borderLeft: `1px solid ${this.props.theme.neutralMedium}`,
        borderRight: `1px solid ${this.props.theme.neutralMedium}`,
      },
      header: {
        display: 'flex',
        flexDirection: 'column',
      },
      headerExt: {
        display: 'grid',
        gridTemplateColumns: '1fr 120px',
      },
      breadcrumbs: {
        fontSize: '1rem',
        margin: '0rem 0rem 0.5rem 0rem',
      },
      name: {
        fontSize: '1.5rem',
        fontWeight: '400',
        height: '2rem',
      },
      button: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      content: {
        margin: '1rem 0rem 0rem 0rem',
      },
    }
  }
  render() {
    if (this.props.botError) {
      return <Error description={this.props.botError} />
    }

    if (this.props.botData.get('isLoading')) {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <LoadingAnim />
        </div>
      )
    }

    return (
      <div style={this.styles.container}>
        <RedirectWithoutPermission permissionName="edit.bots" />
        <div style={this.styles.header}>
          <div style={this.styles.breadcrumbs}>
            <Breadcrumb>
              <BreadcrumbItem
                style={{ cursor: 'pointer', color: this.props.theme.primary }}
                onClick={() =>
                  this.props.push({ pathname: '/App/BotManagement' })
                }
              >
                All Bots
              </BreadcrumbItem>
              <BreadcrumbItem> Bot Details </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <BorderedCard>
            <Scrollable horizontalScrollOnly style={this.styles.name}>
              {this.props.botData.getIn(['data', 'title'])}
            </Scrollable>
            <BotProperties
              handleRefreshBot={this.props.handleRefreshBot}
              botData={this.props.botData.get('data').toJS()}
              isValid={this.props.sourceUrlValidation.get('isValid')}
              isValidating={this.props.sourceUrlValidation.get('isValidating')}
              clearSourceUrlValidation={this.props.clearSourceUrlValidation}
              validateSourceUrl={this.props.validateSourceUrl}
            />
          </BorderedCard>
        </div>
        <div style={this.styles.content}>
          <NodeViewTabs
            style={{
              minHeight: '100%',
              display: 'grid',
              gridTemplateRows: 'auto 1fr',
            }}
          >
            <div label="Manifest Details">
              <div style={this.styles.tabContainer}>
                <ManifestDetails />
              </div>
            </div>
            <div label="Bot Assignments" style={this.styles.tabContainer}>
              <div style={this.styles.tabContainer}>
                <BotAssignmentsTab />
              </div>
            </div>
            <div label="Activity" style={this.styles.tabContainer}>
              <div style={this.styles.tabContainer}>
                <ActivityTab />
              </div>
            </div>
          </NodeViewTabs>
        </div>
        {this.props.botData.get('data') && (
          <Helmet
            title={`Sonrai - ${getNameForHelmet(
              this.props.botData.get('data').toJS()
            )}`}
          />
        )}
      </div>
    )
  }
}

BotDetails.propTypes = {
  botData: ImmutablePropTypes.map,
  botError: PropTypes.string,
  getBotDetails: PropTypes.func,
  push: PropTypes.func,
  theme: themeShape,
  sourceUrlValidation: ImmutablePropTypes.map,
  validateSourceUrl: PropTypes.func,
  clearSourceUrlValidation: PropTypes.func,
  handleRefreshBot: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  botData: selectBot,
  botError: selectBotError,
  sourceUrlValidation: selectSourceUrlValidation,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      getBotDetails,
      validateSourceUrl,
      clearSourceUrlValidation,
      handleRefreshBot,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'botDetails', reducer })
const withSaga = injectSaga({ key: 'botDetails', saga: saga })

export default compose(
  withReducer,
  withSaga,
  withConnect
)(themeable(BotDetails))
