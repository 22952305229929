import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { push } from 'connected-react-router'
import DataTable from 'components/DataTable'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import CenterContent from 'components/CenterContent'
import SectionHeader from 'components/SectionHeader'

import { selectBot, selectBotActions } from './selectors'
import { getBotActions } from './actions'
class ActivityTab extends Component {
  constructor(props) {
    super(props)

    props.getBotActions(props.botData.getIn(['data', 'srn']))
  }

  getActions = () => {
    if (this.props.botActions && !this.props.botActions.get('data').isEmpty()) {
      return (
        this.props.botActions
          .get('data')
          .toJS()
          // .map(item => item.policy)
          .map(action => ({
            action: action.name || '',
            date: action.createdDate || 'N/A',
            srn: action.srn,
            status: action.succeeded ? 'Succeeded' : 'Failed',
          }))
      )
    }
    return []
  }

  render() {
    if (this.props.botActions.get('isLoading')) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <SquareLoadingAnimation />
        </CenterContent>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <SectionHeader> Actions </SectionHeader>
        <div style={{ height: '100%' }}>
          <DataTable
            hiddenColumns={['srn']}
            data={this.getActions()}
            customColumnConfig={{
              status: {
                cellRendererFramework: ({ data }) => {
                  if (!data) {
                    return
                  }
                  const color =
                    data.status === 'Succeeded'
                      ? this.props.theme.success
                      : this.props.theme.red
                  return <div style={{ color }}>{data.status}</div>
                },
              },
            }}
          />
        </div>
      </div>
    )
  }
}

ActivityTab.propTypes = {
  botData: ImmutablePropTypes.map,
  getBotActions: PropTypes.func,
  botActions: ImmutablePropTypes.map,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  botData: selectBot,
  botActions: selectBotActions,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      getBotActions,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(themeable(ActivityTab))
