import { all, put, takeLatest, call } from 'redux-saga/effects'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import {
  GET_BOT_DETAILS,
  GET_BOT_ASSIGNMENTS,
  GET_BOT_ACTIONS,
  VALIDATE_SOURCE_URL,
  HANDLE_REFRESH_BOT,
} from './constants'
import {
  setBotDetails,
  setBotAssignments,
  setBotActions,
  validateSourceUrlSuccess,
  updateBotSuccess,
  setBotError,
  handleRefreshBotSuccess,
} from './actions'
import _ from 'lodash'

function* getBotDetails(action) {
  const {
    payload: { srn },
  } = action
  try {
    const client = getClient()
    const query = gql`
      query getABotBySrn($srn: String) {
        Bots(where: { srn: { value: $srn } }) {
          items {
            title
            description
            type
            isCustom
            url
            status
            cloud
            operation
            lastSync
            version
            srn
            resourceId
            blob
            remediationType
          }
        }
      }
    `
    const result = yield client.query({
      query,
      variables: {
        srn,
      },
    })
    const bot = _.first(result.data.Bots.items)

    if (!bot) {
      throw new Error('No bot returned')
    }

    yield put(setBotDetails(bot))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error getting bot details ${e}`)
    yield put(setBotError(e.message))
  }
}
function* getBotAssignments(action) {
  const { payload } = action
  try {
    const client = getClient()
    const query = gql`
      query getBotAssignemnts($srn: String) {
        BotAssignments(where: { botSrn: { value: $srn } }) {
          items {
            srn
            content {
              srn
              name
            }
          }
        }
      }
    `
    const result = yield client.query({
      query,
      variables: {
        srn: payload,
      },
    })
    const assignments = result.data.BotAssignments.items
    yield put(setBotAssignments(assignments))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error getting bot assignments ${e}`)
    yield put(setBotError(e.message))
  }
}

function* getBotActions(action) {
  const { payload } = action
  try {
    const client = getClient()
    const query = gql`
      query getBotActions($srn: String!) {
        Actions(where: { botSrns: { op: CONTAINS, value: $srn } }) {
          items(limit: 25) {
            srn
            name
            createdDate
            succeeded
          }
        }
      }
    `
    const result = yield client.query({
      query,
      variables: {
        srn: payload,
      },
    })
    const actions = result.data.Actions.items
    yield put(setBotActions(actions))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error getting bot actions ${e}`)
    yield put(setBotError(e.message))
  }
}

function* updateBot(srn, url) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation updateBot($url: String!, $srn: String!) {
          UpdateBot(input: { srn: $srn, url: $url }) {
            title
            description
            type
            isCustom
            url
            status
            cloud
            operation
            lastSync
            version
            srn
            blob
          }
        }
      `,
      variables: { url, srn },
    })
    if (result.data.UpdateBot) {
      return result.data.UpdateBot
    } else {
      return {}
    }
  } catch (e) {
    return {}
  }
}

function* refreshBot(action) {
  const { srn } = action.payload
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation syncBot($srn: String!) {
          SyncBot(input: { srn: $srn }) {
            title
            description
            type
            isCustom
            url
            status
            cloud
            operation
            lastSync
            version
            srn
            resourceId
            blob
          }
        }
      `,
      variables: { srn },
    })

    if (!result || !result.data || result.errors) {
      throw new Error(
        _.get(result, ['errors', 0, 'message']) || 'Could not sync bot'
      )
    }

    const bot = result.data.SyncBot
    yield put(handleRefreshBotSuccess(bot))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error refreshing bot ${e}`)
    yield put(setBotError(e.message))
  }
}

function* validateSourceUrl(action) {
  const { url, srn } = action.payload
  const bot = yield call(updateBot, srn, url)
  const isValid = !_.isEmpty(bot)
  yield put(validateSourceUrlSuccess(isValid))
  if (isValid) {
    yield put(updateBotSuccess(bot))
  }
}

function* explorerSaga() {
  yield all([
    takeLatest(GET_BOT_DETAILS, getBotDetails),
    takeLatest(GET_BOT_ASSIGNMENTS, getBotAssignments),
    takeLatest(GET_BOT_ACTIONS, getBotActions),
    takeLatest(VALIDATE_SOURCE_URL, validateSourceUrl),
    takeLatest(HANDLE_REFRESH_BOT, refreshBot),
  ])
}

export default explorerSaga
