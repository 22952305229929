import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectBotDetailsDomain = state => state.get('botDetails') || Map()

export const selectBot = createSelector(
  selectBotDetailsDomain,
  state => state.get('botData')
)

export const selectBotAssignments = createSelector(
  selectBotDetailsDomain,
  state => state.get('botAssignments')
)

export const selectBotActions = createSelector(
  selectBotDetailsDomain,
  state => state.get('botActions')
)

export const selectSourceUrlValidation = createSelector(
  selectBotDetailsDomain,
  state => state.get('sourceUrlValidation')
)

export const selectBotError = createSelector(
  selectBotDetailsDomain,
  state => state.get('error')
)
