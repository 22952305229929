/*
 *
 * Bot Details reducer
 *
 */

import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  GET_BOT_DETAILS,
  SET_BOT_DETAILS,
  GET_BOT_ASSIGNMENTS,
  SET_BOT_ASSIGNMENTS,
  GET_BOT_ACTIONS,
  SET_BOT_ACTIONS,
  VALIDATE_SOURCE_URL,
  VALIDATE_SOURCE_URL_SUCCESS,
  CLEAR_SOURCE_URL_VALIDATION,
  UPDATE_BOT_SUCCESS,
  SET_BOT_ERROR,
  HANDLE_REFRESH_BOT,
  HANDLE_REFRESH_BOT_SUCCESS,
} from './constants'

const initialState = fromJS({
  error: null,
  botData: { data: {}, isLoading: false },
  botAssignments: { data: [], isLoading: false },
  botActions: { data: [], isLoading: false },
  sourceUrlValidation: { isValid: undefined, isValidating: false },
})

const botManagementReducer = handleActions(
  {
    [GET_BOT_DETAILS]: state => state.setIn(['botData', 'isLoading'], true),
    [SET_BOT_DETAILS]: (state, { payload }) =>
      state
        .setIn(['botData', 'data'], fromJS(payload))
        .setIn(['botData', 'isLoading'], false),
    [GET_BOT_ASSIGNMENTS]: state =>
      state.setIn(['botAssignments', 'isLoading'], true),
    [SET_BOT_ASSIGNMENTS]: (state, { payload }) =>
      state
        .setIn(['botAssignments', 'data'], fromJS(payload))
        .setIn(['botAssignments', 'isLoading'], false),
    [GET_BOT_ACTIONS]: state => state.setIn(['botActions', 'isLoading'], true),
    [SET_BOT_ACTIONS]: (state, { payload }) =>
      state
        .setIn(['botActions', 'data'], fromJS(payload))
        .setIn(['botActions', 'isLoading'], false),
    [VALIDATE_SOURCE_URL]: state =>
      state.setIn(['sourceUrlValidation', 'isValidating'], true),
    [VALIDATE_SOURCE_URL_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['sourceUrlValidation', 'isValid'], payload)
        .setIn(['sourceUrlValidation', 'isValidating'], false),
    [CLEAR_SOURCE_URL_VALIDATION]: state =>
      state.setIn(['sourceUrlValidation', 'isValid'], undefined),
    [UPDATE_BOT_SUCCESS]: (state, { payload }) =>
      state.setIn(['botData', 'data'], fromJS(payload)),

    [SET_BOT_ERROR]: (state, { payload }) => state.set('error', payload),
    [HANDLE_REFRESH_BOT]: state => {
      return state.setIn(['botData', 'isLoading'], true)
    },
    [HANDLE_REFRESH_BOT_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['botData', 'data'], fromJS(payload))
        .setIn(['botData', 'isLoading'], false),
  },
  initialState
)

export default botManagementReducer
